var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main delivery-detail" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c("van-cell", {
            attrs: {
              title: "状态",
              value: _vm.utils.statusFormat(
                String(_vm.form.status),
                "StorageOutStatus"
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "支付批次",
              value: _vm.utils.isEffectiveCommon(
                _vm.form.paymentApplicationNumber
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提单号",
              value: _vm.utils.isEffectiveCommon(
                _vm.form.paymentApplicationNumber
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "实际入库日期",
              value: _vm.utils.dateFormat(_vm.form.finishInTime)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "订单名称",
              value: _vm.utils.isEffectiveCommon(_vm.form.orderName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "订单编号",
              value: _vm.utils.isEffectiveCommon(_vm.form.orderNo)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "产品名称",
              value: _vm.utils.isEffectiveCommon(_vm.form.goodsName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "当前库存重量(KG)",
              value: _vm.utils.moneyFormat(_vm.form.stockWeight)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "可提重量(KG)",
              value: _vm.utils.moneyFormat(
                Number(_vm.form.stockWeight) - Number(_vm.form.lockWeight)
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "仓库名称",
              value: _vm.utils.isEffectiveCommon(_vm.form.warehouseName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "申请提货数量(KG)",
              value: _vm.utils.moneyFormat(_vm.form.applyWeight)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "申请提货日期",
              value: _vm.utils.dateFormat(_vm.form.applyDate)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提货联系人",
              value: _vm.utils.isEffectiveCommon(_vm.form.contacts)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提货单位",
              value: _vm.utils.isEffectiveCommon(_vm.form.dsEnterpriseName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "历史盈余金额(元)",
              value: _vm.utils.isEffectiveCommon(_vm.form.todo)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "实际提货日期",
              value: _vm.utils.dateFormat(_vm.form.finishDate)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "实际提货数量(KG)",
              value: _vm.utils.moneyFormat(_vm.stockedWeight)
            }
          })
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 基本信息 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }