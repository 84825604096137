<!-- 委托客户 提货记录详情 -->
<template>
  <div class="van-main delivery-detail">
    <div class="area-title">
      <p class="title">
        基本信息
      </p>
    </div>
    <div class="van-common-detail">
      <van-cell title="状态" :value="utils.statusFormat(String(form.status), 'StorageOutStatus')" />
      <van-cell title="支付批次" :value="utils.isEffectiveCommon(form.paymentApplicationNumber)" />
      <van-cell title="提单号" :value="utils.isEffectiveCommon(form.paymentApplicationNumber)" />
      <van-cell title="实际入库日期" :value="utils.dateFormat(form.finishInTime)" />
      <van-cell title="订单名称" :value="utils.isEffectiveCommon(form.orderName)" />
      <van-cell title="订单编号" :value="utils.isEffectiveCommon(form.orderNo)" />
      <van-cell title="产品名称" :value="utils.isEffectiveCommon(form.goodsName)" />
      <van-cell title="当前库存重量(KG)" :value="utils.moneyFormat(form.stockWeight)" />
      <van-cell title="可提重量(KG)" :value="utils.moneyFormat(Number(form.stockWeight)-Number(form.lockWeight))" />
      <van-cell title="仓库名称" :value="utils.isEffectiveCommon(form.warehouseName)" />
      <van-cell title="申请提货数量(KG)" :value="utils.moneyFormat(form.applyWeight)" />
      <van-cell title="申请提货日期" :value="utils.dateFormat(form.applyDate)" />
      <van-cell title="提货联系人" :value="utils.isEffectiveCommon(form.contacts)" />
      <van-cell title="提货单位" :value="utils.isEffectiveCommon(form.dsEnterpriseName)" />
      <van-cell title="历史盈余金额(元)" :value="utils.isEffectiveCommon(form.todo)" />
      <van-cell title="实际提货日期" :value="utils.dateFormat(form.finishDate)" />
      <van-cell title="实际提货数量(KG)" :value="utils.moneyFormat(stockedWeight)" />
    </div>
    <back-button />
  </div>
</template>

<script>
import BackButton from '@/components/back-button'
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, Popup, List, NavBar, Calendar, Field, Picker } from 'vant'
export default {
  components: {
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [Popup.name]: Popup,
    [List.name]: List,
    [NavBar.name]: NavBar,
    [Calendar.name]: Calendar,
    [Field.name]: Field,
    [Picker.name]: Picker
  },
  data () {
    return {
      id: this.$route.query.id,
      form: {
      },
      finishInTime: '',
      stockedWeight: 0
    }
  },
  computed: {
  },
  created () {
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.api.storageManagementByWeight.out.outDetail(this.id).then(result => {
        this.form = result.data.value
        // this.getInPlanDetail()
        this.getOutList()
      }).finally(() => {
      })
    },
    // getInPlanDetail () {
    //   this.api.storageManagementByWeight.inWarehouse.detail(this.form.inPlanId).then(result => {
    //     this.finishInTime = result.data.value.finishInTime
    //   }).finally(() => {
    //   })
    // },
    getOutList () {
      this.api.storageManagementByWeight.outList.listPage({ outId: this.form.outId, pageSize: 1000 }).then(result => {
        const outList = result.data.value.list
        let stockedWeight = 0
        if (outList.length > 0) {
          outList.forEach(item => {
            stockedWeight += item.totalWeight
          })
        }
        this.stockedWeight = stockedWeight
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.delivery-detail {
  margin-bottom: 70px;
}
</style>
